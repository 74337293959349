type Cookie = {
  key: string;
  value: string;
};

export const parseCookies = (cookies: string): Cookie[] =>
  cookies
    .split(';')
    .map(pair => pair.split('='))
    .filter(pair => pair.length === 2)
    .map(pair => ({ key: pair[0].trim(), value: pair[1].trim() }));

const readCookies = (): Cookie[] => parseCookies(document.cookie);

export default readCookies;

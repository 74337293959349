import { Environment } from '../types';
import { getLoginUrl } from './loginUrl';

export const getRedirectUrl = (
  env: Environment,
  referralUrl: string,
  redirectUrl?: string
): string => {
  const loginPortalUrl = redirectUrl || getLoginUrl(env);
  return `${loginPortalUrl}?referral=${referralUrl}`;
};

import readCookies from './readCookies';

const IdTokenPattern = 'CognitoIdentityServiceProvider.*.idToken';
const userDataPattern = 'CognitoIdentityServiceProvider.*.userData';

type CognitoUserData = {
  Username: string;
  UserAttributes: {
    Name: string;
    Value: string;
  }[];
};

type UserData = { [name: string]: string };

export const getIdToken = (): string | undefined => {
  const cookies = readCookies();
  const idToken = cookies.find(c => c.key.match(IdTokenPattern));
  return idToken ? idToken.value : undefined;
};

export const getUserData = (): UserData | undefined => {
  const cookies = readCookies();
  const userDataCookie = cookies.find(c => c.key.match(userDataPattern));

  if (userDataCookie == null) {
    return undefined;
  }

  const userData: CognitoUserData = JSON.parse(
    decodeURIComponent(userDataCookie.value)
  );

  return userData.UserAttributes.reduce(
    (result, attr) => Object.assign(result, { [attr.Name]: attr.Value }),
    {}
  );
};

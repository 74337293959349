import React, { ComponentType, FunctionComponent } from 'react';

import { Environment } from './types';
import { getUserData } from './cookies/authCookie';
import { getRedirectUrl } from './redirect/redirectUrl';

type WithAuthenticatorOptions = {
  env: Environment;
  redirectUrl?: string;
};

const withCookieAuthenticator = <T extends object>(
  Component: ComponentType<T>,
  options: WithAuthenticatorOptions
) => {
  const AppWithAuthenticator: FunctionComponent<T> = props => {
    const user = getUserData();

    if (user == null) {
      const redirectUrl = getRedirectUrl(
        options.env,
        window.location.host,
        options.redirectUrl
      );

      window.location.assign(redirectUrl);
      return null;
    }

    return <Component {...props} />;
  };

  return AppWithAuthenticator;
};

export default withCookieAuthenticator;
